<template>
  <div v-if="!hasPercentageBar" class="flex-col cntr">
    <div class="loading-icon" />
    <div class="loading-text">
      {{ loadingText }}
    </div>
  </div>
  <div v-else class="loading-bar-wrapper">
    <div class="loading-bar" :style="loadingBar" />
    <div class="flex between">
      <div class="loading-bar-text">
        {{ loadingText }}
      </div>
      <div class="loading-bar-text">{{ loadingPercent }}%</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { colors } from '../util/color';

const props = defineProps({
  loadingText: {
    type: String,
    default: 'Loading...',
  },
  hasPercentageBar: {
    type: Boolean,
    default: false,
  },
  loadingPercent: {
    type: Number || undefined,
    default: 0,
    required: false,
  },
});

const loadingBar = computed(() => {
  if (props.hasPercentageBar) {
    return `background: linear-gradient(to right, ${colors.secondary500} 0%, ${colors.secondary500} ${props.loadingPercent}%, ${colors.neutralCool200} ${props.loadingPercent}%, ${colors.neutralCool200} 100%);`;
  }
  return '';
});
</script>

<style lang="scss" scoped>
@keyframes loading {
  from {
    background-color: $color-neutral-cool-100;
  }

  to {
    background-color: $color-primary-500;
  }
}

.loading-icon {
  margin-bottom: 7px;
  animation-name: loading;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  @include local-mixins.backgroundMaskImage(
    local-functions.svg-img-source('fire-logo.svg'),
    $color-neutral-cool-100,
    63px,
    60px
  );
}

.loading-text {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  text-align: center;
  color: $color-neutral-black;
}

.loading-bar-text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 25px;
}

.loading-bar-wrapper {
  max-width: 350px;
}

.loading-bar {
  width: 100%;
  height: 4px;
  border-radius: 8px;
  margin-bottom: 4px;
}
</style>

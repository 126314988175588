export const colors = {
  primary50: '#ffede5',
  primary100: '#ffdacc',
  primary200: '#ffb699',
  primary300: '#ff9268',
  primary400: '#ff6c33',
  primary500: '#e4531b',
  primary600: '#b64316',
  primary700: '#902f09',
  primary800: '#651d01',
  primary900: '#330e00',
  secondary50: '#e6f6fe',
  secondary100: '#cceeff',
  secondary200: '#99dcff',
  secondary300: '#66cbff',
  secondary400: '#53afdf',
  secondary500: '#4293bd',
  secondary600: '#42728a',
  secondary700: '#2f566a',
  secondary800: '#1d3a49',
  secondary900: '#0e1d25',
  neutralWhite: '#ffffff',
  neutralBlack: '#000000',
  neutralCool50: '#f3f3f3',
  neutralCool100: '#e5e5e5',
  neutralCool200: '#cccccc',
  neutralCool300: '#b2b2b2',
  neutralCool400: '#999999',
  neutralCool500: '#808080',
  neutralCool600: '#666666',
  neutralCool700: '#4d4d4d',
  neutralCool800: '#333333',
  neutralCool900: '#1a1a1a',
  neutralWarm50: '#f5f2ef',
  neutralWarm100: '#ebe5e0',
  neutralWarm200: '#d8cbc0',
  neutralWarm300: '#c4b2a1',
  neutralWarm400: '#b19881',
  neutralWarm500: '#9d7e62',
  neutralWarm600: '#7e654e',
  neutralWarm700: '#5e4c3b',
  neutralWarm800: '#3f3227',
  neutralWarm900: '#1f1914',
  successDark: '#207944',
  successDefault: '#5ed48d',
  successLight: '#ebfaf1',
  warningDark: '#867313',
  warningDefault: '#e6cc4c',
  warningLight: '#fbfbe9',
  dangerDark: '#7d1c1c',
  dangerDefault: '#d95959',
  dangerLight: '#ffe5e6',
};

export const themeColors = {
  secondary: colors.secondary600,
  danger: colors.dangerDark,
  success: colors.successDark,
  warning: colors.warningDark,
  white: colors.neutralWhite,
  black: colors.neutralBlack,
};

<script lang="ts" setup>
import { ImageOptions } from '~/composables/cloudinary';

const emit = defineEmits(['cld-image-loaded']);

const props = defineProps({
  publicId: {
    type: String,
    default: undefined,
  },
  cloudinarySrc: {
    type: String,
    default: undefined,
  },
  hoverPublicId: {
    type: String as () => string | undefined,
    default: undefined,
  },
  options: {
    type: Object as () => ImageOptions | undefined,
    default: undefined,
  },
  alt: {
    type: String,
    default: '',
  },
  imageClassesOverride: {
    type: Array as () => (string | Record<string, boolean>)[],
    default: () => [],
  },
  enableLogging: {
    type: Boolean,
    default: false,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
});

const cloudinary = useCloudinary(props.enableLogging);

const currPublicId = ref<string | undefined>(undefined);
const currentSrc = ref<string | undefined>(undefined);
const currQualityIndex = ref(0);
const currHoverQualityIndex = ref(0);
const minQuality = ref(cloudinary.qualityArray[0]);
const maxQuality = ref(cloudinary.qualityArray[cloudinary.qualityArray.length - 1]);
const minLoaded = ref(false);
const maxLoaded = ref(false);
const isHover = ref(false);

const imageUrls = computed(() =>
  currPublicId.value ? cloudinary.generateProgressiveImageUrls(currPublicId.value, props.options) : []
);
const hoverImageUrls = computed(() =>
  props.hoverPublicId ? cloudinary.generateProgressiveImageUrls(props.hoverPublicId, props.options) : []
);
// const currentSrc = computed(() => {
//   const mainImg = maxLoaded.value
//     ? imageUrls.value[cloudinary.qualityArray.length - 1]
//     : imageUrls.value[currQualityIndex.value];
//   const hoverImg = maxLoaded.value
//     ? hoverImageUrls.value[cloudinary.qualityArray.length - 1]
//     : hoverImageUrls.value[currHoverQualityIndex.value];
//   return isHover.value && hoverImg?.url ? hoverImg.url : mainImg?.url;
// });
const width = computed(() => props.options?.width);
const height = computed(() => props.options?.height);
const imageClasses = computed(() => {
  const defaults: { [key: string]: boolean } = {
    'min-loaded': minLoaded.value,
    'max-loaded': maxLoaded.value,
  };
  props.imageClassesOverride.forEach((cls) => {
    if (typeof cls === 'string') {
      defaults[cls] = true;
    } else {
      Object.keys(cls).forEach((key) => {
        defaults[key] = cls[key];
      });
    }
  });
  return defaults;
});

const setHover = (hover: boolean) => {
  isHover.value = hover;
  if (props.hoverPublicId) setCurrentSrc();
};

const setCurrentSrc = () => {
  const mainImg = maxLoaded.value
    ? imageUrls.value[cloudinary.qualityArray.length - 1]
    : imageUrls.value[currQualityIndex.value];
  const hoverImg = maxLoaded.value
    ? hoverImageUrls.value[cloudinary.qualityArray.length - 1]
    : hoverImageUrls.value[currHoverQualityIndex.value];
  if (isHover.value && hoverImg?.url) {
    currentSrc.value = hoverImg.url;
  } else if (props.cloudinarySrc) {
    currentSrc.value = props.cloudinarySrc;
  } else if (mainImg?.url && currentSrc.value !== mainImg?.url) {
    currentSrc.value = mainImg?.url;
  }
  // currentSrc.value = isHover.value && hoverImg?.url ? hoverImg.url : mainImg?.url;
};
// const currentSrc = computed(() => {
//   const mainImg = maxLoaded.value
//     ? imageUrls.value[cloudinary.imageQualityArray.length - 1]
//     : imageUrls.value[currQualityIndex.value];
//   const hoverImg = maxLoaded.value
//     ? hoverImageUrls.value[cloudinary.imageQualityArray.length - 1]
//     : hoverImageUrls.value[currHoverQualityIndex.value];
//   return isHover.value && hoverImg?.url ? hoverImg.url : mainImg?.url;
// });

const log = (message: string) => {
  if (props.enableLogging) {
    // @todo figure out logging
    console.log(message);
  }
};

const preloadImage = (
  src: string,
  quality: number | string,
  index: number,
  isHoverImage = false
): Promise<{ src: string; index: number; quality: number | string }> => {
  return new Promise((resolve) => {
    try {
      if (typeof Image === 'undefined') {
        if (process.server) {
          useHead({
            link: [{ hid: 'prefetch', rel: 'prefetch', href: src }],
          });
          setCurrentSrc();
          resolve({ src, index, quality });
        }
      } else {
        const img = new Image();
        const startTime = performance.now();
        img.src = src;
        if (index > currQualityIndex.value) {
          currQualityIndex.value = index;
        }
        if (isHoverImage && index > currHoverQualityIndex.value) {
          currHoverQualityIndex.value = index;
        }
        img.onload = () => {
          setCurrentSrc();
          const endTime = performance.now();
          // @todo remove this log?
          log(`Image loaded: Quality ${quality}, URL: ${src}, Time: ${endTime - startTime} ms`);
          if (!isHoverImage && quality === maxQuality.value) {
            maxLoaded.value = true;
            emit('cld-image-loaded');
            log(
              `Image displayed: Index ${imageUrls.value.findIndex(
                (img) => img.url === src
              )}, Quality ${quality}, URL: ${src}`
            );
          } else if (!isHoverImage && quality === minQuality.value) {
            minLoaded.value = true;
            log(
              `Image displayed: Index ${imageUrls.value.findIndex(
                (img) => img.url === src
              )}, Quality ${quality}, URL: ${src}`
            );
          } else if (!isHoverImage && quality !== minQuality.value && quality !== maxQuality.value) {
            log(`Image preloaded: Quality ${quality}, URL: ${src}`);
          }
          resolve({ src, index, quality });
        };
        img.onerror = () => {
          log(`Image failed to load: Quality ${quality}, URL: ${src}`);
          resolve({ src, index, quality });
        };
      }
    } catch (error) {
      log(`Error loading image: ${error}`);
      resolve({ src, index, quality });
    }
  });
};

async function handleLoadImages() {
  try {
    currPublicId.value = props.publicId;
    handleLoadHoverImages();

    const minImg = (imageUrls.value || []).find((img) => img.quality === minQuality.value);
    const maxImg = (imageUrls.value || []).find((img) => img.quality === maxQuality.value);
    if (props.cloudinarySrc) {
      await preloadImage(props.cloudinarySrc, 'auto', 0);
      return;
    }

    if (minImg) {
      await preloadImage(minImg.url, minQuality.value, 0);
    }

    if (maxImg) {
      const maxPromise = preloadImage(maxImg.url, maxQuality.value, imageUrls.value.length - 1);
      let otherPromises = [];

      // Preload all other images except min and max
      otherPromises = imageUrls.value
        .filter((img) => img !== minImg && img !== maxImg)
        .map((img, i) => preloadImage(img.url, img.quality, i + 1));

      // Wait for max promise and other promises simultaneously
      await Promise.race([maxPromise, Promise.all(otherPromises)]);
    }
  } catch (error) {
    console.error('Error loading images:', error);
  }
}

const handleLoadHoverImages = function () {
  Promise.all(hoverImageUrls.value.map((img, i) => preloadImage(img.url, img.quality, i, true))).then(() => {
    log('Hover images loaded');
  });
};

watch(
  () => props.publicId,
  () => {
    maxLoaded.value = false;
    minLoaded.value = false;
    handleLoadImages();
  }
);
handleLoadImages();
</script>

<template>
  <div class="cloudinary-image-container">
    <Loader v-if="minLoaded !== true && !props.cloudinarySrc" loading-text="" class="cld-loader" />
    <img
      :key="currentSrc"
      :src="currentSrc"
      :class="imageClasses"
      :width="width"
      :height="height"
      :alt="alt"
      loading="lazy"
      :style="props.transparent ? `background-color: transparent` : ''"
      @mouseenter="setHover(true)"
      @mouseleave="setHover(false)"
    />
  </div>
</template>

<style scoped lang="scss">
.cloudinary-image-container {
  position: relative;
  .cld-loader {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    scale: 0.5;
    opacity: 0.5;
    transform: translate(-50%, -50%);
    z-index: map.get(local-vars.$zindex, 'page');
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    background-color: $color-neutral-cool-50;
    transition: filter 0.35s ease-in-out;
  }
}
</style>
